import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { customerAdd } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import ToTop from "../includes/ToTop";
import $ from "jquery";
import InnerHTML from "dangerously-set-html-content";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";
import Select from "react-select";

export default function AddCustomer() {
  const state = useSelector((state) => state.stateVals);

  const { customerAccessToken, customer_id, customerSiteId } = state;
  const [loading, setLoading] = useState(false);
  const [isEditable, setisEditable] = useState(false);

  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [EmailChecking, setEmailChecking] = useState(false);
  const [EditId, setEditId] = useState("");
  const [duplicateMsg, setduplicateMsg] = useState("");
  const [popUpData, setpopUpData] = useState("");

  const [UserData, setUserData] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");

  const [StatesList, setStatesList] = useState([]);
  const [StatesListCopy, setStatesListCopy] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const fileInputRef = useRef(null);

  const getUsers = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.getCustomersData(
        customer_id,
        "1"
      );

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            UserId: value.UserId,
            FirstName: value.FirstName,
            LastName: value.LastName,
            City: value.City,
            State: value.State,
            ZipCode: value.ZipCode,
            PhoneNumber: value.PhoneNumber,
            Address: value.Address,
            Email: value.Email,
            Denomination: value.Denomination,
            voucher: value.voucher,
            clientcost: value.clientcost,
            invoice: value.invoice,
            date_created: value.date_created,
          });
        });

        setUserData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setUserData([]);
      setLoading(false);
    }
  };
  const getStatesNames = async () => {
    try {
      const responce = await AdminListService.getStates();

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.code,
          label: value.state_name,
        });
      });
      setStatesList([...results]);
      setStatesListCopy([...results]);
    } catch (err) {
      console.log(err);
      setStatesList([]);
    }
  };

  useEffect(() => {
    if (customerAccessToken) {
      getUsers();

      getStatesNames();
    }
    // eslint-disable-next-line
  }, []);

  const delData = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        setErrorData([]);
        setInvalidEmails([]);
        setduplicateMsg("");
        try {
          const response = await AdminListService.deleteCustomersData(
            delId,
            customer_id
          );
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              getUsers();
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };

  const delSelected = async () => {
    var selectedRecords = $(".table tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedRecords.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        confirmButtonColor: "#e72e2e",
        text: "Please select some record!",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete record",
        icon: "question",
        iconColor: "#e04f5d",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Yes",
        cancelButtonText: "Close",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          setErrorData([]);
          setInvalidEmails([]);
          setduplicateMsg("");
          try {
            const response = await AdminListService.deleteSelectedCustomersData(
              selectedRecords,
              customerSiteId,
              customer_id
            );
            if (response.status === 200) {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Deleted Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(() => {
                getUsers();
              }, 1500);
            }
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              confirmButtonColor: "#e72e2e",
              text: "Something went wrong!",
            });
            setLoading(false);
          }
        }
      });
    }
  };

  const delDataAll = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        setErrorData([]);
        setInvalidEmails([]);
        setduplicateMsg("");
        try {
          const response = await AdminListService.deleteCustomersDataAll(
            customer_id
          );
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              getUsers();
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };

  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
    setFileError(false);
  };

  const putCustomers = async () => {
    if (file === "") {
      setFileError(true);
    } else {
      setFileError(false);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("AdminId", customer_id);
      formData.append("sessionId", customer_id);

      try {
        setLoading(true);

        const response = await AdminListService.uploadCustomers(
          formData,
          customer_id
        );

        if (response.status === 200) {
          setLoading(false);
          fileInputRef.current.value = "";
          setFile("");
          setErrorData([]);
          setduplicateMsg("");
          setInvalidEmails([]);
          Swal.fire({
            icon: "success",
            title: "Added Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            getUsers();
          }, 1500);
        }
      } catch (err) {
        console.log(err);

        setLoading(false);

        if (
          err?.response?.data?.data?.data?.invalidEmails?.invalidEmail.length &&
          Array.isArray(
            err?.response?.data?.data?.data?.invalidEmails?.invalidEmail
          )
        ) {
          const errors = [];

          err?.response?.data?.data?.data?.invalidEmails?.invalidEmail.map(
            (value) => {
              return errors.push({
                row: value.row,
                errors: value,
              });
            }
          );

          setInvalidEmails([...errors]);

          Swal.fire({
            icon: "warning",
            title: "Invalid Emails Found!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            getUsers();
          }, 1500);
        } else {
          setInvalidEmails([]);
          Swal.fire({
            icon: "error",
            iconColor: "#e04f5d",
            title: "Error in uploading customers",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            getUsers();
          }, 1500);
        }
        if (err?.response?.data?.data?.data) {
          if (
            err?.response?.data?.data?.data?.failure !== "" &&
            err?.response?.data?.data?.data?.duplicate === ""
          ) {
            if (Array.isArray(err?.response?.data?.data?.data?.failure)) {
              const errors = [];

              err?.response?.data?.data?.data?.failure.map((value) => {
                return errors.push({
                  row: value.row,
                  errors: value.errors.toString().replaceAll("_", " "),
                });
              });

              setErrorData([...errors]);
              setduplicateMsg("");
            }
          } else {
            setduplicateMsg(err?.response?.data?.data?.data?.message);
            setErrorData([]);
          }
        } else if (err?.response?.data) {
          if (err?.response?.data?.failure !== "") {
            if (Array.isArray(err?.response?.data?.failure)) {
              setduplicateMsg(err?.response?.data?.failure[0]);
            }
          }
        }
      }
    }
  };

  const varifyEmail = async (e) => {
    setEmailError("");
    if (e.target.value !== "") {
      setEmailChecking(true);
      let response;
      try {
        if (isEditable) {
          response = await AdminListService.updateCustomerEmail(
            e.target.value,
            EditId,
            customer_id
          );
        } else {
          response = await AdminListService.checkCustomerEmail(
            e.target.value,
            customer_id
          );
        }
        if (response.status === 200) {
          setEmailError("");
          setEmailChecking(false);
        }
      } catch (err) {
        if (err.response?.status === 409) {
          setEmailError("Email already exists");
          setEmailChecking(false);
        }
      }
    }
  };

  const addCustomer = async (values, action) => {
    if (emailError === "") {
      try {
        setErrorData([]);
        setInvalidEmails([]);
        setduplicateMsg("");
        setLoading(true);

        const response = await AdminListService.addCustomer(
          values,
          customer_id,
          customerAccessToken
        );
        if (response.status === 200) {
          action.resetForm();
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Added Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          await setStatesList([]);

          setTimeout(() => {
            setStatesList(StatesListCopy);
            getUsers();
          }, 1500);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);

        Swal.fire({
          icon: "error",
          iconColor: "#e04f5d",
          title: "Oops...",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };
  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.getCustomersData(
          customer_id,
          pageNo
        );

        let res;

        if (responce.status === 200) {
          res = responce.data.data.data;

          const results = [];

          res.map((value) => {
            return results.push({
              UserId: value.UserId,
              FirstName: value.FirstName,
              LastName: value.LastName,
              City: value.City,
              State: value.State,
              ZipCode: value.ZipCode,
              PhoneNumber: value.PhoneNumber,
              Address: value.Address,
              Email: value.Email,
              Denomination: value.Denomination,
              voucher: value.voucher,
              clientcost: value.clientcost,
              invoice: value.invoice,
              date_created: value.date_created,
            });
          });

          setUserData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.data.total_records);
          setTotalPages(responce.data.data.total_pages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setUserData([]);
        setLoading(false);
      }
    };
    getPaginationData();
  };

  const getCustomerWithId = async (id) => {
    try {
      setLoading(true);

      const response = await AdminListService.getCustomersDataById(
        id,
        customer_id
      );

      if (response.status === 200) {
        setEditId(response.data.data.id);
        setValues({
          fname: response.data.data.fname,
          lname: response.data.data.lname,
          address: response.data.data.address,
          city: response.data.data.city,
          state: response.data.data.state,
          zip: response.data.data.zip,
          email: response.data.data.email,
          phone: response.data.data.phone ? response.data.data.phone : "",
          denomination: response.data.data.denomination,
        });

        setisEditable(true);
        await setStatesList([]);

        setTimeout(() => {
          setStatesList(StatesListCopy);
        }, 100);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setisEditable(false);
      Swal.fire({
        icon: "error",
        iconColor: "#e04f5d",
        title: "Oops...",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const showModelData = async () => {
    try {
      setLoading(true);

      const response = await AdminListService.getDataForSubmit(customer_id);
      if (response.status === 200) {
        setpopUpData(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setpopUpData("");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const submitCustomerPop = async () => {
    try {
      setLoading(true);

      const response = await AdminListService.submitFinalCustomers(
        customer_id,
        customerSiteId
      );
      if (response.status === 200) {
        setLoading(false);
        setisEditable(false);
        resetForm();
        Swal.fire({
          icon: "success",
          title: "Submitted Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        getUsers();
      }
    } catch (err) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const UpdateCustomer = async (values, action) => {
    if (emailError === "") {
      try {
        setLoading(true);

        const response = await AdminListService.updateCustomer(
          values,
          customer_id,
          EditId,
          customerAccessToken
        );

        if (response.status === 200) {
          setLoading(false);
          action.resetForm();
          setEditId("");
          setisEditable(false);
          Swal.fire({
            icon: "success",
            title: "Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          await setStatesList([]);
          setTimeout(() => {
            setStatesList(StatesListCopy);
            getUsers();
          }, 1500);
        }
      } catch (err) {
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      denomination: "",
    },
    validationSchema: customerAdd,
    onSubmit: (values, action) => {
      if (isEditable) {
        UpdateCustomer(values, action);
      } else {
        addCustomer(values, action);
      }
    },
  });

  const Table = ({ data }) => {
    const allSelect = async () => {
      $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
    };

    return (
      <>
        {data.length ? (
          <>
            <div className=" alert border-0 border-info border-start border-4 alert-info alert-dismissible fade show py-2 mt-3">
              <div className="d-flex align-items-center">
                <div className="fs-3 text-info">
                  <i className="bi bi-info-circle-fill"></i>
                </div>
                <div className="ms-3">
                  <div className="text-info text-info-bold">
                    Must Submit Data to Finalize{" "}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div className="card mt-4">
              <div className="card-header ">
                <h5 className="mb-0">
                  Manage {process.env.REACT_APP_SITE_NAME} Users
                  <button
                    type="button"
                    id="889@ashfurn"
                    onClick={() => {
                      showModelData();
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleExtraLargeModalsunmit"
                    className="btn btn-primary d-inline-block float-end "
                  >
                    Submit Data
                  </button>
                  <div className="clearfix"></div>
                </h5>
              </div>
              <div className="card-body">
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    delSelected();
                  }}
                >
                  <i className="bi bi-trash-fill"></i> Delete Selected Users
                </button>

                <button
                  className="btn btn-danger mt-3 ms-3"
                  onClick={() => {
                    delDataAll();
                  }}
                >
                  <i className="bi bi-trash-fill"></i> Delete All Users
                </button>

                <div className="table-responsive mt-3">
                  <table className="table table-striped table-bordered W-100">
                    <thead>
                      <tr>
                        <th>
                          <input
                            className="form-check-input slect_all"
                            type="checkbox"
                            onChange={allSelect}
                          />
                        </th>
                        <th>First Name</th>
                        <th>Last Name </th>
                        <th>Address </th>
                        <th>City </th>
                        <th>State</th>
                        <th>Zip Code</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Denomination </th>
                        <th>Voucher Number</th>
                        <th>Client Cost</th>
                        <th>Invoice</th>
                        <th>Date Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((el, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id=""
                              value={el.UserId}
                            />
                          </td>
                          <td>{el.FirstName}</td>
                          <td>{el.LastName}</td>
                          <td>{el.Address}</td>
                          <td>{el.City}</td>
                          <td>{el.State}</td>
                          <td>{el.ZipCode}</td>
                          <td>{el.PhoneNumber}</td>
                          <td>{el.Email}</td>
                          <td>{el.Denomination}</td>
                          <td>{el.voucher === "" ? "N/A" : el.voucher}</td>
                          <td>
                            {el.clientcost === "" ? "N/A" : el.clientcost}
                          </td>
                          <td>{el.invoice === "" ? "N/A" : el.invoice}</td>
                          <td>
                            {/* {el.date_created === "" ? "N/A" : el.date_created} */}
                          </td>
                          <td>
                            <div className="d-flex align-items-center gap-3 fs-6">
                              <button
                                className="text-warning open-model-btn "
                                onClick={() => {
                                  setEmailError("");
                                  getCustomerWithId(el.UserId);
                                }}
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              <button
                                type="button"
                                className="text-danger open-model-btn "
                                onClick={() => {
                                  delData(el.UserId);
                                }}
                              >
                                <i className="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {totalResults > limit && totalPages > 1 ? (
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={parseInt(limit)}
                      totalItemsCount={totalResults}
                      className="justify-content-center"
                      onChange={(e) => {
                        changePagination(e);
                      }}
                      pageRangeDisplayed={8}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First Page"
                      lastPageText="Last Page"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };
  const Duplicate = ({ data }) => {
    return (
      <>
        {data !== "" ? (
          <>
            <div className="alert alert-danger ">
              <h5 className="m-0">Error!</h5>{" "}
              <InnerHTML html={data ? data : ""} />
            </div>
          </>
        ) : null}
      </>
    );
  };

  const PopupData = ({ data }) => {
    return (
      <>
        {data !== "" ? (
          <>
            <tbody dangerouslySetInnerHTML={{ __html: data ? data : "" }} />
          </>
        ) : null}
      </>
    );
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} Customer</title>
      </Helmet>
      <main className="page-content customer-contnent">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="card h-100">
              <div className="card-header py-3">
                <h3 className="mb-0">
                  {isEditable ? "Update " : "Add "}a Customer
                </h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex">
                    <div className="card border shadow-none w-100">
                      <div className="card-body">
                        <form
                          className="row g-3"
                          onSubmit={handleSubmit}
                          noValidate
                        >
                          <div className="col-12">
                            <span>
                              <strong>
                                Please enter the information below, if you want
                                to add a customer one by one. Field(s) with *
                                are mandatory.
                              </strong>
                            </span>
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              First Name{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              name="fname"
                              value={values.fname || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.fname && touched.fname ? (
                              <span className="text-danger">
                                {errors.fname}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Last Name{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              name="lname"
                              value={values.lname || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.lname && touched.lname ? (
                              <span className="text-danger">
                                {errors.lname}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Address <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              value={values.address || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.address && touched.address ? (
                              <span className="text-danger">
                                {errors.address}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              City <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              value={values.city || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.city && touched.city ? (
                              <span className="text-danger">{errors.city}</span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              State <strong className="text-danger">*</strong>
                            </label>

                            {/* <select
                              className="form-select"
                              name="state"
                              value={values.state || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select State</option>
                              {StatesList.map((el, index) => {
                                return (
                                  <option key={index} value={el.value}>
                                    {el.label}
                                  </option>
                                );
                              })}
                            </select> */}
                            {StatesList.length ? (
                              <Select
                                className="form-label"
                                placeholder={
                                  isEditable && values.state !== ""
                                    ? values.state
                                    : "Select State"
                                }
                                options={StatesList}
                                isSearchable={true}
                                isClearable={true}
                                name="state"
                                onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    setFieldValue(
                                      "state",
                                      selectedOption.value
                                    );
                                  } else {
                                    setFieldValue("state", "");
                                  }
                                }}
                                onBlur={() => setFieldTouched("state", true)}
                              />
                            ) : null}

                            {errors.state && touched.state ? (
                              <span className="text-danger">
                                {errors.state}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Zip Code{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip Code"
                              name="zip"
                              value={values.zip || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.zip && touched.zip ? (
                              <span className="text-danger">{errors.zip}</span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">Phone Number </label>
                            <input
                              type="string"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phone"
                              value={values.phone || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone ? (
                              <span className="text-danger">
                                {errors.phone}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Email
                              {/* <strong className="text-danger">*</strong> */}
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={values.email || ""}
                              onChange={(e) => {
                                handleChange(e);
                                varifyEmail(e);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email ? (
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            ) : emailError !== "" ? (
                              <span className="text-danger">{emailError}</span>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="form-label">
                              Denomination{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Denomination"
                              name="denomination"
                              value={values.denomination || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.denomination && touched.denomination ? (
                              <span className="text-danger">
                                {errors.denomination}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="d-flex h-100 w-100 align-items-end mb-mob">
                                  <button
                                    className="btn btn-primary w-100"
                                    type="submit"
                                    disabled={EmailChecking}
                                  >
                                    {EmailChecking ? (
                                      <div
                                        className="spinner-border text-light"
                                        role="status"
                                      ></div>
                                    ) : null}
                                    {isEditable ? "Update Data" : "Add Data"}
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="d-flex h-100 w-100 align-items-end mb-mob">
                                  <button
                                    type="button"
                                    className="btn btn-primary w-100"
                                    onClick={resetForm}
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="row">
              {invalidEmails.length
                ? invalidEmails.map((res, index) => {
                    return (
                      <div className="col-lg-6">
                        <div className="alert alert-danger " key={index}>
                          <b>Invalid Emails: </b>
                          {res.errors}
                        </div>
                      </div>
                    );
                  })
                : null}

              {errorData.length
                ? errorData.map((res, index) => {
                    return (
                      <div className="col-lg-6">
                        <div className="alert alert-danger " key={index}>
                          <b>Note!</b> Problem in row number {res.row} ,
                          <b> Error! </b>
                          {res.errors}
                        </div>{" "}
                      </div>
                    );
                  })
                : null}
              <div className="col-lg-6">
                <Duplicate data={duplicateMsg} />
              </div>
            </div>

            <div className="card">
              <div className="card-header py-3">
                <h3 className="mb-0">Upload Multiple Customers</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-lg-12 d-flex">
                    <div className="card border shadow-none w-100">
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-12">
                            <a
                              href="/assets/csv/bca.xlsx"
                              className="btn btn-primary w-100"
                              download={true}
                              target="_blank"
                            >
                              Download Sample Sheet
                            </a>
                          </div>
                          <br />
                          <div className="col-12">
                            <strong>
                              Please select a Excel or CSV file containing
                              multiple customers information, before you upload
                              make sure you have all columns filled out for each
                              record (customer), Once you selected the file,
                              please hit "Upload Customer Data" Button
                            </strong>
                          </div>

                          <div className="col-md-8">
                            <label
                              htmlFor="formFileMultiple"
                              className="form-label"
                            >
                              Select (.CSV, .XLS, .XLSX) File
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              ref={fileInputRef}
                              id="formFileMultiple"
                              accept=".xlsx,.xls,.csv"
                              onClick={(e) => {
                                e.target.value = null;
                                setFile("");
                              }}
                              onChange={handleUpload}
                            />
                          </div>
                          <div className="col-md-4">
                            <div className="d-flex h-100 w-100 align-items-end">
                              <button
                                className="btn btn-primary w-100"
                                onClick={putCustomers}
                              >
                                Upload Customer Data
                              </button>
                            </div>
                          </div>
                          <div className="col-12">
                            {fileError ? (
                              <span className="text-danger">
                                Please Select File
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Table data={UserData} />
      </main>

      <div
        className="modal fade "
        id="exampleExtraLargeModalsunmit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h6 className="mb-0 text-uppercase">
                <strong>You are going to submit</strong>
              </h6>

              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered w-100">
                  <PopupData data={popUpData} />
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  submitCustomerPop();
                }}
              >
                Submit Data
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
