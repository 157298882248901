import React, { useEffect, useRef, useReducer, useState } from "react";

import "./style.css";
import "./CardsIdex.css";
import "./Button.css";
import "./Input_fields.css";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";
import { Buffer } from "buffer";
import axios from "axios";
import { Helmet } from "react-helmet";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, data: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

function CardsIndex(props) {
  const [{ loading, error, data }, dispatch] = useReducer(reducer, {
    data: [],
    loading: true,
    error: "",
  });
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState([]);
  const [value_three, setValue_three] = useState([]);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const navigate = useNavigate();
  const TITLE = "BCA Rewards | Cards Selection Form";

  const ref = useRef();
  useEffect(() => {
    test();
  }, []);

  // localStorage.setItem("returnpage", true);
  // localStorage.setItem("page", "/Card_index");

  const cardSession = (ind) => {
    const cardData = value_three.filter((value) => {
      return value.id === ind;
    });

    if (cardData) {
      localStorage.setItem("card_image", cardData[0].image);
      localStorage.setItem("card_name", cardData[0].name);
      localStorage.setItem("card_id", cardData[0].id);
      localStorage.setItem("card_type", cardData[0].type);

      // props.history.push({
      //   pathname: "/UserForm",
      //   state: {
      //     cardImage: cardData[0].image,
      //     cardName: cardData[0].name,
      //     cardID: cardData[0].id,
      //   },
      // });

      localStorage.setItem(
        "CardIndex",
        JSON.stringify({
          cardImage: cardData[0].image,
          cardName: cardData[0].name,
          cardID: cardData[0].id,
        })
      );

      navigate("/user-form");
    }
  };

  async function test() {
    dispatch({ type: "FETCH_REQUEST" });
    let errors = {};

    var token_value = localStorage.getItem("voucher");
    var username = process.env.REACT_APP_API_USERNAME;
    var password = process.env.REACT_APP_API_PASSWORD;
    const token_tow = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    var bodyFormData = new FormData();
    bodyFormData.append("certificate", token_value);

    try {
      try {
      } catch (e) {
        console.log(e);
      }

      const response_sheet = await axios({
        method: "post",
        url: process.env.REACT_APP_API_Link + "getCards",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Basic ${token_tow}`,
          "Access-Control-Allow-Origin": process.env.REACT_APP_API_ORIGIN,
        },
      });
      const value_three = response_sheet.data.data;
      console.log("cards :", value_three);
      setValue_three(value_three);
      if (value.includes("redirect")) {
      } else {
        errors["voucher"] = response_sheet.data.response;
        dispatch({ type: "FETCH_SUCCESS", payload: value_three });
      }
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      } else if (err.message.includes("400")) {
        dispatch({
          type: "FETCH_FAIL",
          payload: "Failed to load cards",
        });
      } else {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    }
    timeout();
  }

  function timeout() {
    // alert(signoutTime);
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.removeItem("userform");
    localStorage.removeItem("userformopen");
    localStorage.removeItem("voucher");
    localStorage.removeItem("page");
    localStorage.removeItem("card_id");
    localStorage.removeItem("card_name");
    localStorage.removeItem("card_image");
    // localStorage.removeItem("ans1");
    // localStorage.removeItem("ans2");
    // localStorage.removeItem("ans3");
    window.location.replace("/");
    return false;
  };

  const Voucher = localStorage.getItem("voucher");
  if (Voucher === undefined || Voucher === null) {
    localStorage.setItem("returnpage", false);
    localStorage.removeItem("page");
    // localStorage.removeItem("ans1");
    // localStorage.removeItem("ans2");
    // localStorage.removeItem("ans3");
    localStorage.setItem("userformopen", false);
    localStorage.setItem("userform", false);
    localStorage.setItem("cardIndex", false);
    return <Navigate to="/" />;
  }
  <Navigate to="/card-form" />;
  if (Voucher === undefined || Voucher === null) {
    localStorage.setItem("returnpage", false);
    localStorage.setItem("userformopen", false);
    localStorage.setItem("userform", false);
    localStorage.removeItem("page");
    // localStorage.removeItem("ans1");
    // localStorage.removeItem("ans2");
    // localStorage.removeItem("ans3");
    return <Navigate to="/" />;
  }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        {/* <HeaderLower /> */}
        <div id="main-registration-container">
          <div className="white-box form">
            <h1 className="text-center">
              {" "}
              {process.env.REACT_APP_CARINDEXFORMTITLE}
            </h1>

            <div className="row">
              <br />
              <div className="clearfix"></div>
              <div className="col-md-12" id="card-section">
                <div className="row justify-content-md-center">
                  <span style={{ float: "left" }} id="color_msg22"></span>
                  <div className="errorMsg">{errors.checkederrorfive}</div>
                  {loading ? (
                    <LoadingBox />
                  ) : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                  ) : (
                    value_three.map((insdex, ind) => {
                      return (
                        <>
                          <span
                            className="error_message_forimage"
                            id="color_msg22"
                          ></span>
                          <div className="col-md-4">
                            <div className="check-boexs1 cen">
                              <div className="reward" id="test">
                                <label>
                                  <span className="insdex">{insdex.name}</span>
                                  {insdex.image ? (
                                    <img
                                      src={
                                        process.env.REACT_APP_API_IMAGES +
                                        insdex.image
                                      }
                                      className="imgcolor img-responsive"
                                    />
                                  ) : (
                                    <div className="pirds pirds2">
                                      <span className="rdcrdtop">
                                        <table border="0">
                                          <tr>
                                            <td>{insdex.name}</td>
                                          </tr>
                                        </table>
                                      </span>
                                    </div>
                                  )}
                                </label>
                              </div>
                              <div className="Card-Text">
                                <p className="CardDesc">{insdex.paragraph}</p>
                                {/* <span id="span-text">{insdex.span}</span> */}
                              </div>
                              <button
                                ClassName="cardButton"
                                className="card_selection"
                                id={insdex.id}
                                onClick={() => cardSession(insdex.id)}
                              >
                                {insdex.button}
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
              <span
                className="error"
                style={{ display: "none" }}
                id="radio_msg"
              />
              <br></br>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default CardsIndex;
